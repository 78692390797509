import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Tabs } from "antd";
import { GET_MEMBER_OVERVIEW_INFORMATION } from "../../containers/Admin/graphql/queries";
import MemberTransactions from "../Admin/Transactions/memberTransactions";
import "./index.css";

const AllTransactions = () => {
  const userRole = localStorage.getItem("userRoles");
  const roleStream = localStorage.getItem("roleStream");
  const memberId = Number(localStorage.getItem("userId"));
  const [companyId, setCompanyId] = useState(null);
  const isEmployee = userRole?.includes("Employee");

  const [fetchMemberOverviewInformation] = useLazyQuery(
    GET_MEMBER_OVERVIEW_INFORMATION,
    {
      variables: {
        userId: memberId,
      },
      onCompleted: (data) => {
        if (typeof data?.userData?.employment[0]?.company?.id === "number") {
          setCompanyId(data.userData.employment[0].company.id);
        }
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (!isEmployee) fetchMemberOverviewInformation();
  }, []);

  const tabItems = [
    {
      label: "Transactions",
      key: "1",
      children: (
        <>
          {companyId &&
            ["Sole Proprietor", "Company"].indexOf(userRole) > -1 &&
            roleStream === "Member" && (
              <MemberTransactions
                mode="company-overview-transactions"
                companyId={companyId}
              />
            )}
          {memberId && userRole === "Employee" && roleStream === "Member" && (
            <MemberTransactions
              mode="member-overview-transactions"
              memberId={memberId}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="tasks-header-div">
        <h2>Transactions</h2>
      </div>
      <Tabs tabPosition="top" items={tabItems} />
    </>
  );
};

export default AllTransactions;
